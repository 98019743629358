import React from "react";
import icon1 from "../../assets/images/how_they_are_unique_icons/icon1.png";
import icon2 from "../../assets/images/how_they_are_unique_icons/icon2.png";
import icon3 from "../../assets/images/how_they_are_unique_icons/icon3.png";
import icon4 from "../../assets/images/how_they_are_unique_icons/icon4.png";
import { useState } from "react";
import Modal from "../Modal/Modal";
import Popup from "../Modal/Popup";
import HowUniquePopup from "../Modal/HowUniquePopup";
import { NavLink } from "react-router-dom";

const howUniqueCards = [
  {
    id: 0,
    icon: icon1,
    title: "You mint, you decide",
    desc: `When you mint—i.e. create a new—NFT, you decide how funds are distributed between ${process.env.REACT_APP_WEBSITE_BENEFICIARY}, the artist, the Fund Raisin team, and the seller, each time your newly minted NFT is traded, for its entire life. You can mint an NFT where most of the funds go to ${process.env.REACT_APP_WEBSITE_BENEFICIARY}, or you can mint one where most of the funds go to the seller, or to the artist, for instance. It is your choice.`,
  },
  {
    id: 1,
    icon: icon2,
    title: "Rare & Infinite",
    desc: "One octopus pattern, the π, is rare! Only 20 of each per background color will ever exist. The rest of the patterns are infinite! You can mint as many as you want to the number of NFTs that can be minted, so have fun minting! However, some types cost more to mint than others, so some will be much rarer. There is an octopus for every budget.",
  },
  {
    id: 2,
    icon: icon3,
    title: `Support ${process.env.REACT_APP_WEBSITE_BENEFICIARY}`,
    desc: `When you buy or sell an NFT, part of the price paid will go to red·dev's fundraiser for ${process.env.REACT_APP_WEBSITE_BENEFICIARY}. You can check how much using the pie chart associated with each NFT, and you can also sort your search results accordingly. Each week of the 90-day fundraiser, red·dev will send all funds raised so far to ${process.env.REACT_APP_WEBSITE_BENEFICIARY} and publish an audit trail so that you know your donations have reached them.`,
  },
  {
    id: 3,
    icon: icon4,
    title: "Fund Raisin NFTs are green",
    desc: "Because Fund Raisin NFTs are minted on the Avalanche platform, it takes a tiny amount of electricity, similar to the amount used to make toast or brew coffee thanks to its novel consensus protocol. Also it's fast! Minting is a quick process that uses only a tiny bit of the Avalanche network’s capacity for less than a second.",
  },
];
const HowUnique = () => {
  const [show, setShow] = useState(false);
  const [cardId, setCardId] = useState(0);

  const openModal = (index) => {
    setCardId(index);
    setShow(!show);
  };

  const closeModal = () => {
    setShow(!show);
  };

  return (
    <>
      <span className="link_wrapper_about" id="about"></span>
      <section className="how-unique default-settings gradient-bg">
        <h1 className="how-unique__title">How are they unique?</h1>
        <div className="hr-line"></div>
        <p className="how-unique__desc">
          {process.env.REACT_APP_WEBSITE_FUNDRAISER} is an example of how Fund Raisin can be used to
          raise money for NGOs. It's purpose is to help you use Octopi to
          fundraise for {process.env.REACT_APP_WEBSITE_BENEFICIARY} which, as a core part of its
          mission, focuses on preserving marine life in our oceans.
        </p>
        <div className="how-unique__cards">
          {howUniqueCards.map((item) => (
            <div
              key={item.id}
              className="how-unique__card"
              onClick={() => openModal(item.id)}
            >
              <div className="how-unique__card-icon-box">
                <img
                  className="how-unique__card-icon"
                  src={item.icon}
                  alt="icon"
                ></img>
              </div>
              <h1 className="how-unique__card-title">{item.title}</h1>
              <div className="how-unique__card-btn">
                <span className="how-unique__card-btn-text">Learn More</span>
              </div>
            </div>
          ))}
        </div>
        <NavLink to="/faqs" className="faqs-link-wrapper">
          <h3 className="faqs-link">More Answers to Common Questions</h3>
        </NavLink>
        <Modal show={show}>
          <Popup>
            <HowUniquePopup
              item={howUniqueCards[cardId]}
              onClose={closeModal}
            />
          </Popup>
        </Modal>
      </section>
    </>
  );
};

export default HowUnique;
