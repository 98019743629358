import React from "react";
import constant from "../../utils/constant";

const FinalCheck = ({
  selectedItems,
  artOptions,
  sliders,
  preset,
  title,
  rarityData,
}) => {
  const getPercentage = (s) => {
    if (s.category === "beneficiary") {
      return selectedItems.distribution.beneficiaryDistro / 100;
    } else if (s.category === "fundraiser") {
      return selectedItems.distribution.fundraiserDistro / 100;
    } else if (s.category === "artist") {
      return selectedItems.distribution.artistDistro / 100;
    } else {
      return selectedItems.distribution.sellerDistro / 100;
    }
  };

  const formatDate = (date) => {
    return (
      date.getFullYear().toString().slice(-2) +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (date.getDate())).slice(-2) +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2)
    );
  };

  const calculateDate = (start, end) => {
    let startDate = start ? new Date(start * 1000) : null;
    let endDate = end ? new Date(end * 1000) : null;
    let startDay = startDate ? formatDate(startDate) : null;
    let endDay = endDate ? formatDate(endDate) : null;
    return { startDay, endDay };
  };

  const getRareInfo = (catergory) => {
    let start = rarityData[catergory]?.start;
    let end = rarityData[catergory]?.end;
    let remainingCounter = rarityData[catergory]?.remainingCounter;
    let totalAllowed = rarityData[catergory].totalAllowed;
    let type = rarityData[catergory].type;

    if (start || end || remainingCounter) {
      let tooltipMsg = `Rarity of this ${type}:`;
      let dates = calculateDate(start, end);
      if (dates.startDay && dates.endDay)
        tooltipMsg += `<br>- Mint from ${dates.startDay} to ${dates.endDay}. `;
      else if (dates.endDay) {
        tooltipMsg += `<br>- Minting ends on ${dates.endDay}. `;
      }
      if (remainingCounter > 0) {
        tooltipMsg += `<br>- Only ${remainingCounter} of ${totalAllowed} left.`;
      }
      return tooltipMsg;
    }
  };

  const getStyle = (item) => {
    if (item.widget === "catalogue") {
      if (item.identifier === "background") {
        return {
          backgroundColor: selectedItems[item.identifier].colorHexCode,
        };
      } else if (item.identifier === "pattern") {
        return {
          backgroundImage: `url(${
            selectedItems[item.identifier].thumbnailURL
          })`,
          backgroundSize: "180px",
          backgroundPosition: "center",
        };
      } else if (item.identifier === "layerchoice") {
        return {
          backgroundImage: `url(${
            selectedItems[item.identifier].layerPreviewURL
          })`,
          backgroundSize: "180px",
          backgroundPosition: "center",
        };
      }
    } else {
    }
  };
  return (
    <>
      <h3 className="mint__step-title">{title}</h3>
      <div className="mint__final-list">
        <div className="final-data">
          <div className="final-data-left">
            <table className="final-data__nft-styles">
              <tbody>
                {artOptions.map((option) => {
                  if (option.widget === "catalogue") {
                    return (
                      <React.Fragment key={option.title}>
                        <tr>
                          <td className="table-text-header">
                            <div
                              className="final-data__nft-box"
                              style={getStyle(option)}
                            ></div>
                            <span style={{ paddingLeft: '10px', verticalAlign: 'super'}}>{option.title}</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="table-rare-text"
                            dangerouslySetInnerHTML={{
                              __html: getRareInfo(option.identifier),
                            }}
                          ></td>
                        </tr>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
          <div className="final-data-right">
            <table className="final-data__dist-division">
              <tbody>
                <tr>
                  <th className="table-text-header" style={{textAlign: 'left'}}>{constant.DISTRIBUTION}</th>
                  <th className="table-text-header" style={{width: '35%'}}>
                    {preset === "I Only Love Octopi"
                      ? "I Only ❤️ Octopi"
                      : preset}
                  </th>
                </tr>
                {sliders.map((slider, index) => {
                  return (
                    <tr key={slider.name}>
                      <td className="table-text-data">{slider.name}</td>
                      <td className="table-text-data">{`${getPercentage(
                        slider
                      )} %`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(FinalCheck);
