import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper } from "swiper/react";
import { Navigation } from "swiper";
import SimpleCard from "../SimpleCard";
import { NavLink } from "react-router-dom";
import Loader from "../Loader";

const MyOctopi = ({ walletAddress, nftData = [], loading = true }) => {
  //const [nftData, setNftData] = useState(nft);
  //const [loading, setLoading] = useState(false);

  const renderCardData = () => {
    //need to add the lenght of nftData
    if (walletAddress) {
      if (loading) {
        return (
          <div className="nft-cards">
            <Loader loading={loading} />
          </div>
        );
      } else {
        if (nftData.length === 0)
          return <h1 className="no-octopi-text">
            <NavLink to="/buy">Buy</NavLink> or <NavLink to="/mint">mint</NavLink> your first octopus!
          </h1>;
        else if (nftData.length <= 3) {
          return (
            <>
              <div className="my-nfts" style={{ justifyContent: "center" }}>
                {nftData.map((simpleCard) => (
                  <div className="nft-grid-simplecard__wrapper">
                    <SimpleCard key={simpleCard.id} simpleCard={simpleCard} overlayText={simpleCard.forSale}/>
                  </div>
                ))}
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="myoctopi__swipper">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={50}
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  <div className="swiper-wrapper swiper-wrap">
                    {nftData.map((simpleCard) => (
                      <div key={simpleCard.id} className="swiper-slide">
                        <SimpleCard
                          key={simpleCard.id}
                          simpleCard={simpleCard}
                          overlayText={simpleCard.forSale}
                        />
                      </div>
                    ))}
                  </div>
                </Swiper>
              </div>
            </>
          );
        }
      }
    } else {
      return <h1 className="no-octopi-text">
        <NavLink to="/buy" className="link-design">Buy</NavLink> or <NavLink to="/mint" className="link-design">mint</NavLink> your first octopus!
      </h1>;
    }
  };

  return (
    <>
    <span className="link_wrapper" id="my"></span>
    <section className="default-settings">
      <div className="myoctopi__title-box row">
        <div className="myoctopi__title-column column1"></div>
        <div className="myoctopi__title-column column2">
          <h1 className="myoctopi__title">My Octopi</h1>
        </div>
        <div className="myoctopi__title-column column3">
          {nftData.length !== 0 ? (
            <NavLink to="/my">
              <h3 className="myoctopi__viewall">View All and Sell</h3>
            </NavLink>
          ) : (
            ""
          )}
        </div>
      </div>
      <>{renderCardData()}</>
    </section>
    </>
  );
};

export default MyOctopi;
