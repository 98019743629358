import React from 'react'
import fb from "../../assets/images/social_icons/fb.svg"
import twitter from "../../assets/images/social_icons/twitter.svg"
import linkedin from "../../assets/images/social_icons/linkedin.svg"
import instagram from "../../assets/images/social_icons/instagram.svg"
import discord from "../../assets/images/social_icons/discord.svg"
import pwa from "../../assets/images/pwa.svg"
import pwr from "../../assets/images/pwr.png"
import { NavLink } from "react-router-dom";

const Footer = () => {

    const date = new Date()
    const year = date.getFullYear()

    return (
        <footer>
            <div className="footer__social-icons">
                {/* <a href='https://www.facebook.com/reddevinc/' target='_blank'><img className="footer__social-icon social-icon1" src={fb} alt="fb"></img></a> */}
                <a href='https://twitter.com/FundRaisinApp' target='_blank'><img className="footer__social-icon social-icon2" src={twitter} alt="twitter"></img></a>
                {/* <a href='https://www.linkedin.com/company/reddevinc' target='_blank'><img className="footer__social-icon social-icon3" src={linkedin} alt="linkedin"></img></a> */}
                <a href='https://discord.gg/R9g6Jjy7TW' target='_blank'><img className="footer__social-icon social-icon4" src={discord} alt="discord"></img></a>
                <a href='https://www.instagram.com/FundRaisinApp' target='_blank'><img className="footer__social-icon social-icon4" src={instagram} alt="instagram"></img></a>
            </div>
            <div className="footer__copyright">
                <span className="footer__copyright-text">© {year} red.dev Inc. All Rights Reserved.</span>
                <div className="footer__pwb">
                    <div className="footer__pwb-inner">
                        <div className="footer__pwb-avalanche">
                            <img className="front" src={pwa} alt="pwa" />
                        </div>
                        <div className="footer__pwb-reddev">
                            <img className="back" src={pwr} alt="pwr" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
