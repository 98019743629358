import React, { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import download_icon from "../assets/images/download_icon.svg";
import share_icon from "../assets/images/share_icon.svg";

const Card = ({
  card,
  btn,
  show,
  download,
  overlay,
  overlayText,
  newest,
  onBtnOpen,
  onPieOpen,
  onDownload,
  onShare
}) => {
  const [tooltiptext, setTooltipText] = useState("Copy Address");
  const imgClass = overlay ? "card__img img-overlay" : "card__img";

  const download_btn_style = download
    ? { visibility: "visible" }
    : { visibility: "hidden" };

  const showType = () => {
    if (card.distPresetName) {
      return (
        <h4 className="card__text card__type">
          {card.distPresetName === "I Only Love Octopi"
            ? "I Only ❤️ Octopi"
            : card.distPresetName}
        </h4>
      );
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(card.address);
    setTooltipText("Copied");
  };

  const showAddress = () => {
    if (card.address) {
      return (
        <div
          className="card__text card__addr tooltip"
          onClick={copyToClipboard}
          onMouseOut={() => setTooltipText("Copy Address")}
        >
          {` ${card.address.slice(0, 6)}...${card.address.slice(
            card.address.length - 4
          )} `}
          <span className="tooltiptext">{tooltiptext}</span>
        </div>
      );
    }
  };

  const showButton = () => {
    if (btn) {
      return (
        <div>
          <button className="card__btn" onClick={() => onBtnOpen(card, btn)}>
            {btn}
          </button>
        </div>
      );
    }
  };

  const showOverlayText = () => {
    if (overlayText) {
      return <h3 className="card__img-overlay-text">For Sale</h3>;
    }
  };

  const showNewest = () => {
    if (card.mintedAt === newest && show === false) {
      return (
        <div className="ribbon">
          <span>Newest</span>
        </div>
      );
    }
  };

  return (
    <div className="card">
      <div className="card__top-box">
        <img className={imgClass} src={card.image} alt="octopi"></img>
        {showOverlayText()}
        {showNewest()}
        <div
          className="card__download-btn"
          style={download_btn_style}
          onClick={() => onShare(card)}
        >
          <img
            className="card__download-icon"
            src={share_icon}
            alt="download"
          />
        </div>
      </div>
      <div className="card__bottom-box">
        <div className="card__body">
          <div className="pie__wrapper" onClick={onPieOpen}>
            <PieChart
              className="card-pie"
              data={card.pieData}
              startAngle={-90}
              labelPosition={87}
              radius={40}
            />
          </div>
          <div className="card__body-top">
            <h3 className="card__title">{card.name}</h3>
            <div className="group1">
              {showAddress()}
              {showType()}
            </div>
          </div>
          <div className="card__body-bottom">
            {showButton()}
            <h3 className="card__text nft-price">{card.price} AVAX</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Card);
