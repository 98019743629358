// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SNOWTRACE_API: "https://api-testnet.snowtrace.io/api",
  SNOWTRACE_GET_LOGS: "getLogs",
  BUY_ABI:
    "event Buy(address indexed,address,uint256,uint256,address,uint256,string)",
  ALL_NFT_MODE: 0,
  MY_NFT_MODE: 1,
  BUY_NFT_MODE: 2,
  color: ["#A4CB7B", "#FFDC6B", "#F46534", "#1D7EFE"],
  priceFilter: [
    {
      id: 0,
      text: "< 0.1 AVAX",
      selected: false,
      key: "price",
    },
    {
      id: 1,
      text: "0.1 - 0.5 AVAX",
      selected: false,
      key: "price",
    },
    {
      id: 2,
      text: "0.5 - 1.0 AVAX",
      selected: false,
      key: "price",
    },
    {
      id: 3,
      text: "10 - 100 AVAX",
      selected: false,
      key: "price",
    },
    {
      id: 4,
      text: "> 100 AVAX",
      selected: false,
      key: "price",
    }
  ],
  CARDS_PER_PAGE: 12,
  MYCARDS_PER_PAGE: 100,
  CARDS_DISPLAY_MIN_LOADED: 4,
  MAX_TOP_LIST: 3,
  faq: [
    {
      "question":"Why did red�dev create the NFT Art Project?",
      "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi."
    },
    {
      "question":"How does the NFT Art Project innovate in the NFT Art space?",
      "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi."
    },
    {
      "question":"What if I�d like to fork the code? Is NFT Art Project open source?",
      "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi."
    },
    {
      "question":"I am an artist, and I would like to participate. How can I?",
      "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi."
    },
    {
      "question":"I am a fundraiser at an NGO. How can we participate?",
      "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi."
    },
    {
      "question":"I have having technical difficulties. What should I do?",
      "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi."
    },
    {
      "question":"Can NFTs minted here be exhibited and sold on other platforms such as Kalao?",
      "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi."
    }
  ],
  PATTERN: "Pattern",
  PATTERN_COLOR: "Pattern Color",
  PICK_DISTRO: "How would you like the proceeds to be distributed?",
  DISTRIBUTION: "Funds Distribution",
  DIST: "Distribution",
  BACK_TO_PRESET: "Back to Alter-Egos",
  BACKGROUND_COLOR : "Background Color",
  NAME: "Name",
  ALTER_EGO: "Alter-Ego",
  FINAL_CHECK: "Final Check",
  FINAL_TEXT: "Final Check Before Minting",
  TWITTER_API: "https://twitter.com/intent/tweet?text="
};
