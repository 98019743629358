import React, { useEffect } from "react";
import nft_nav_icon from "../../assets/images/nft_nav_icon.png";
import logo from "../../assets/images/logo.svg";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import { logInfo } from "../../utils/log";
import wallet from "../../assets/images/wallet.svg";

const Header = ({ walletAddress, connectWallet }) => {
  const handleWalletClick = () => {
    if (!walletAddress) {
      connectWallet();
    }
  };
  useEffect(() => {
    const checkWallet = () => {
      logInfo("checking wallet");
      if (window.ethereum && window.ethereum.selectedAddress !== null) {
        connectWallet();
      }
    };
    checkWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header>
      <nav className="navbar">
        <div className="nav__logo-box">
          <a
            href={process.env.REACT_APP_FUNDRAISIN_WEBSITE_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <img className="nav__logo" src={logo} alt="logo" />
          </a>
        </div>
        <div className="nav__menu-box">
          <ul className="nav__menu-items">
            <Link
              to="/#home"
              smooth
              offset={500}
              duration={500}
              className="nav__menu-item-box"
            >
              <li className="nav__menu-item"> Home </li>
              <img
                className="nav__menu-item-icon"
                src={nft_nav_icon}
                alt="menu"
              />
            </Link>
            <Link
              to="/#top"
              smooth
              offset={-70}
              duration={500}
              className="nav__menu-item-box"
            >
              <li className="nav__menu-item"> Top </li>
              <img
                className="nav__menu-item-icon"
                src={nft_nav_icon}
                alt="menu"
              />
            </Link>
            <Link
              to="/#my"
              smooth
              offset={-70}
              duration={500}
              className="nav__menu-item-box"
            >
              <li className="nav__menu-item"> My </li>{" "}
              <img
                className="nav__menu-item-icon"
                src={nft_nav_icon}
                alt="menu"
              />
            </Link>
            <Link
              to="/#about"
              smooth
              offset={-70}
              duration={500}
              className="nav__menu-item-box"
            >
              <li className="nav__menu-item"> About </li>{" "}
              <img
                className="nav__menu-item-icon"
                src={nft_nav_icon}
                alt="menu"
              />
            </Link>
            <NavLink to="/mint" className="nav__menu-item-box">
              <li className="nav__menu-item"> Mint </li>{" "}
              <img
                className="nav__menu-item-icon"
                src={nft_nav_icon}
                alt="menu"
              />
            </NavLink>
            <NavLink to="/buy" className="nav__menu-item-box">
              <li className="nav__menu-item"> Buy </li>{" "}
              <img
                className="nav__menu-item-icon"
                src={nft_nav_icon}
                alt="menu"
              />
            </NavLink>
            <span className="nav__menu-item-box">
              <li
                className="nav__menu-item nav__menu-item-btn"
                onClick={handleWalletClick}
              >
                <div className="nav__menu-item-btn-info">
                  <span className="wallet-addr">
                    {" "}
                    {walletAddress
                      ? ` ${walletAddress.slice(0, 6)}...${walletAddress.slice(
                          walletAddress.length - 4
                        )} `
                      : "Connect Your Wallet"}
                  </span>

                  <img
                    src={wallet}
                    alt="wallet"
                    className="wallet-icon"
                    style={
                      walletAddress
                        ? { display: "inline-block" }
                        : { display: "none" }
                    }
                  ></img>
                </div>
              </li>
            </span>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default React.memo(Header);
