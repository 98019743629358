import React from 'react'
import heart_icon from '../../assets/images/heart_icon.png'
import extra_octopi from '../../assets/images/extra_octopi.png'

const Wagmi = () => {
    return (
        <div id='wagmi' className='default-settings'>
            <div className='wagmi__title-box'>
                <img className="wagmi__title-heart-icon" src={heart_icon} alt="octopi"></img>
                <h1 className="wagmi__title-text plus-sign">+</h1>
                <img className="wagmi__title-nft-icon" src={extra_octopi} alt="octopi"></img>
                <h1 className="wagmi__title-text plus-sign">+</h1>
                <h1 className="wagmi__title-text">U</h1>
            </div>
            
            <p className="wagmi__desc">The purpose of Fund Raisin's Octopi project is to demonstrate how the Fund Raisin platform can be used to raise money for non-governmental organizations. If you are an <strong>artist</strong> or represent an <strong>NGO</strong> and are interested in creating a similar project using Fund Raisin, please get in touch with us via Twitter or Discord!</p>
        </div>
    )
}

export default Wagmi
