import React, { useEffect } from "react";
import { Fade } from "react-slideshow-image";
import { HashLink as Link } from "react-router-hash-link";
import octopus1 from "../../assets/images/hero_nfts/octopus-01.png";
import octopus2 from "../../assets/images/hero_nfts/octopus-02.png";
import octopus3 from "../../assets/images/hero_nfts/octopus-03.png";
import { useState } from "react";
import { getTokenOwner } from "../../utils/services";

const Hero = (props) => {
  //const { nftData } = props; for listing top fundraiser
  const [nftData, setNftData] = useState( [
    {
      name: "Pi-rated #1",
      image: octopus1,
      currentOwner: "",
      tokenId: 1
    },
    {
      name: "Ms. Tenti-Cool #1",
      image: octopus2,
      currentOwner: "",
      tokenId: 2
    },
    {
      name: "GM! #3",
      image: octopus3,
      currentOwner: "",
      tokenId: 3
    },
  ]
)
  const owner = async () => {
    let newData = nftData;
    for (let index = 0; index < newData.length; index++) {
      let nft = newData[index];
      newData[index].currentOwner = await getTokenOwner(nft.tokenId);      
    } 
    setNftData([...newData]);
  }


  
  const [text, setText] = useState();

  useEffect(() => {
    if (sessionStorage.getItem("beta") === null) {
      setBeta();
    } else {
      setText(JSON.parse(sessionStorage.getItem("beta")));
    }
    owner();
  }, []);

  const setBeta = () => {
    setText({
      name: "The project is in BETA. Use at your own risk.",
      style: "beta-alert-text-pre",
    });
    setTimeout(() => {
      var beta = {
        name: "BETA",
        style: "beta-alert-text-post",
      };
      setText(beta);
      sessionStorage.setItem("beta", JSON.stringify(beta));
    }, 10000);
  };

  const reset = () => {
    setBeta();
  };

  const getContent = () => {
    if (nftData && nftData.length) {
      return nftData.map((card, index) => {
        return (
          <div className="hero__nft-wrapper" key={index}>
            <div className="hero__nft-img-box">
              <img
                className="hero__nft-img"
                src={card.image}
                alt="Octopus"
              ></img>
            </div>
            <div>
              <p className="hero__nft-name">{card.name}</p>
              <p className="hero__nft-owner">
                 {card.currentOwner.length > 0 ? `Owned by ${card.currentOwner.slice(0, 6)}...${card.currentOwner.slice(card.currentOwner.length - 4)}`: ""}
              </p>
            </div>
          </div>
        );
      });
    }
    return <></>;
  };
  return (
    <>
      <span className="link_wrapper" id="home"></span>
      <section className="hero">
        <div className="hero__box">
          <div className="hero__box-left">
            <div className="beta-alert">
              <span className={text?.style} onMouseEnter={() => reset()}>
                {text?.name}
              </span>
            </div>
            <h1 className="hero__title">{process.env.REACT_APP_WEBSITE_FUNDRAISER}</h1>
            <p className="hero__desc">
            Discover, collect, and trade extraordinary octopus NFT art while saving the oceans with red·dev's fundraiser for <strong>{process.env.REACT_APP_WEBSITE_BENEFICIARY}</strong>.
            </p>
            <Link to="/#about" smooth offset={-70} duration={500}>
              <span className="hero__btn">Seems Rare?</span>
            </Link>
          </div>
          <div className="hero__box-right">
            <Fade autoplay={true}>{getContent()}</Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
